<template>
  <EventCard
    description="Klik op de button om een nieuw evenement toe te voegen aan de App."
    featured
    :image="require('@/assets/img/events/meeting.webp')"
    name="Evenement toevoegen"
  />

  <EventCard
    v-for="event in events"
    :key="event.id"
    :description="event.description"
    :end-date-time="event.endDate"
    :event-id="event.id"
    :event-users="event.eventUsers"
    :image="event.visualImage"
    :name="event.title"
    :start-date-time="event.startDate"
  />
</template>

<script>
import { getAll } from '@/api/providers/events';
import EventCard from '@/components/Events/Card.vue';

export default {
  name: 'EventsIndex',

  components: {
    EventCard,
  },

  data: () => ({
    events: [],
  }),

  mounted() {
    this.loadEvents();
  },

  methods: {
    loadEvents() {
      getAll()
        .then((events) => {
          this.events = events;
        })
        .catch((error) => error.default());
    },
  },
};
</script>
