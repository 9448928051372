<template>
  <div class="event-card col-3 pt-4">
    <div class="card card-blog h-100">
      <div class="card-header p-0 mt-n4 mx-3">
        <router-link
          class="event-card__image-container d-flex justify-content-center align-items-center
                 shadow-xl border-radius-xl bg-gradient-dark"
          :to="link"
        >
          <img
            v-if="image"
            :alt="name"
            class="event-card__image img-fluid shadow w-100 h-100 border-radius-xl"
            :src="image"
          />
          <i
            v-else
            class="d-block material-icons text-secondary fs-1"
          >
            event
          </i>
        </router-link>
      </div>

      <div class="d-flex flex-column card-body p-3">
        <p class="mb-0 text-sm">
          {{ parseDateTimeRange(startDateTime, endDateTime) }}
        </p>

        <router-link :to="link">
          <h5
            :class="[
              { 'text-primary font-weight-bolder mb-0': featured }
            ]"
          >
            {{ name }}
          </h5>
        </router-link>

        <p class="event-card__description mb-4 text-sm overflow-hidden">
          {{ description }}
        </p>

        <div class="d-flex align-items-center justify-content-between mt-auto">
          <router-link
            :class="[
              'btn',
              'btn-sm',
              'mb-0',
              { 'btn-outline-primary': !featured },
              { 'btn-primary': featured },
            ]"
            :to="link"
          >
            {{
              eventId
                ? 'Bekijk evenement'
                : 'Evenement toevoegen'
            }}
          </router-link>

          <div
            v-if="eventUsers.length"
            class="avatar-group mt-2"
          >
            <a
              v-for="(eventUser, i) in eventUsers.slice(0, 4)"
              :key="i"
              href="javascript:;"
              class="avatar avatar-xs rounded-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :aria-label="eventUser.userName"
              :data-bs-original-title="eventUser.userName"
            >
              <img
                :alt="eventUser.userName"
                class="h-100"
                :src="eventUser.userImage"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseDateTimeRange } from '@/helpers/parsers';

export default {
  name: 'EventCard',

  props: {
    description: {
      type: String,
      required: true,
    },
    endDateTime: {
      type: String,
      default: '',
    },
    eventId: {
      type: Number,
      default: 0,
    },
    eventUsers: {
      type: Array,
      default: () => [],
      validator: (value) => value
        .every(
          (item) => [
            'userName',
            'userImage',
          ]
            .every(
              (key) => Object.keys(item).includes(key),
            )
        ),
    },
    featured: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    startDateTime: {
      type: String,
      default: '',
    },
  },

  computed: {
    link() {
      return this.eventId
        ? {
          name: 'edit-event',
          params: {
            id: this.eventId,
          },
        }
        : {
          name: 'new-event',
        };
    },
  },

  methods: {
    parseDateTimeRange,
  },
};
</script>

<style scoped lang="scss">
.event-card {
  &:nth-child(n + 5) {
    margin-top: var(--bs-gutter-x);
  }

  &__image {
    object-fit: cover;

    &-container {
      height: 200px;
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
</style>
